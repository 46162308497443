import moment from "moment";

const day = moment().format("MMMM D");

const boxingDay = moment("12-26");

export const maintenance = () => {
  //christmas

  let maintenanceFlag = false;
  /*xw
  if (boxingDay.isSame(day, "day")) {
    maintenanceFlag = true;
  } else {
    maintenanceFlag = false;
  }*/
  //use for when ordering needs to be turned off and make sure to change the message
  return maintenanceFlag;
};

export const message = () => {
  //christmas
  let message;
  if (boxingDay.isSame(day, "day")) {
    message = "Ken's Kowloon Kitchen is closed for Boxing Day.";
  } else {
    message =
      "Our online ordering system is currently going through maintenance. Please call our restaurant (403-254-6908) to place your pick-up order.";
  }
  return message;
};

//toggle for the message banner to appear
export const messageBannerFlag = false;
export const messageBanner = "We Are Only Open For Dinner On New Years Day";

//Open For Dinner On New Years Day
