import React, { useContext } from "react";
import { Flex, Text, VStack, StackDivider, Box } from "@chakra-ui/react";

import QRCode from "react-qr-code";

function PrintFormat({
  takeoutCart,
  orderPrice,
  name,
  phone,
  address,
  method,
  history,
  time,
  orderNote,
  table,
  copy,
  currentInvoiceNumber,
  skipCode,
  schedule,
  scheduledTime,
}) {
  const zeroPad = (num, places) => String(num).padStart(places, "0");
  const dayjs = require("dayjs");
  var now = dayjs();

  //const { currentInvoiceNumber } = useContext(TakeoutContext);

  const sauceSymbolsComboOne = [
    { code: "Q2", color: "red" },
    { code: "Q3", color: "yellow" },
    { code: "Q5", color: "red" },
    { code: "Q6", color: "red" },
  ];
  const sauceSymbolsDinnerCombo = [
    { code: "D2", color: "red" },
    { code: "D3", color: "yellow" },
    { code: "D5", color: "red" },
    { code: "D6", color: "red" },
  ];

  const sauceSymbols = [
    { code: "A4", color: "red" },
    { code: "S1", color: "red" },
    { code: "C8", color: "red" },
    { code: "C9", color: "yellow" },
  ];

  return (
    <Flex m={3} direction="column" height="100%" width="93%">
      <style type="text/css" media="print">
        {" @page { size: portrait; } "}
      </style>
      <Text
        fontSize="3xl"
        as="i"
        color=" #d90429"
        mt="1rem"
        textShadow="2px 3px #f9c74f"
        fontFamily="langar"
      >
        Ken's Kowloon Kitchen
      </Text>

      <Text>www.kenskowloonkitchen.com</Text>
      <Text>Order Takeout Online</Text>

      <Text>403-279-4471 & 403-279-4472</Text>

      <Flex justify="space-between">
        <Text>By The Glenmore Inn Hotel</Text>
        <Text>{currentInvoiceNumber}</Text>
      </Flex>

      <Box border="1px solid black" mt="5px" borderRadius="5px">
        <Box m={1}>
          <Flex justify="space-between">
            {method === "3" ? <Text>{"Table: " + table}</Text> : null}
            <Text fontSize="lg">{name}</Text>
            <Text fontSize="lg">
              {now.format("MM-DD")}, {history ? time : now.format("HH:mm")}
            </Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text fontSize="lg">{phone}</Text>
            {method === "1" ? (
              <Text fontSize="2xl" fontWeight="bold">
                Pick Up
              </Text>
            ) : method === "2" ? (
              <Text fontSize="2xl" fontWeight="bold">
                Delivery
              </Text>
            ) : method === "3" ? (
              <Text fontSize="2xl" fontWeight="bold">
                Dine In
              </Text>
            ) : method === "4" ? (
              <Text fontSize="2xl" fontWeight="bold">
                Skip - {skipCode}
              </Text>
            ) : method === "5" ? (
              <Text fontSize="xl" fontWeight="bold" color="#CE2029">
                {schedule ? "For Pickup: " + scheduledTime : "Online"}
              </Text>
            ) : null}
          </Flex>

          {method === "1" || method === "2" ? (
            <>
              <Flex justify="space-between">
                {method === "2" ? <Text fontSize="lg">{address}</Text> : null}
              </Flex>
            </>
          ) : null}
        </Box>
      </Box>

      <VStack
        spacing={1}
        align="stretch"
        mt="2rem"
        width="100%"
        border="1px solid black"
        borderRadius="5px"
      >
        {takeoutCart.map((item, i) => {
          return (
            <Flex
              mt="6px"
              key={"tkoc" + i}
              direction="column"
              width="98%"
              alignSelf="center"
              borderBottom={
                i === takeoutCart.length - 1 && orderNote === ""
                  ? "0px dashed black"
                  : "1px dashed black"
              }
            >
              <Flex>
                <Text fontWeight="bold">
                  {item.number}. {item.name} ({item.chineseName})
                </Text>

                <Flex ml="auto" direction="column">
                  <Text
                    border={
                      item.quantity > 1 || item.half
                        ? "1px solid #CE2029"
                        : "1px solid black"
                    }
                    borderRadius="50%"
                    lineHeight="19px"
                    width="22px"
                    height="22px"
                    textAlign="center"
                    verticalAlign="center"
                    color={item.quantity > 1 || item.half ? "#CE2029" : "black"}
                    alignSelf="flex-end"
                    fontWeight="bold"
                  >
                    {item.half ? ".5" : item.quantity}
                  </Text>
                </Flex>
              </Flex>

              <Flex direction="column">
                <Flex>
                  <Flex direction="column" mb="4px">
                    {item.includes &&
                      item.includes.map((selection, j) => {
                        if (selection.chineseName !== "hidden") {
                          return (
                            <Text key={"incj" + j} as="i" ml="20px">
                              {selection.name} ({selection.chineseName})
                            </Text>
                          );
                        } else {
                          return "";
                        }
                      })}
                    {item.special
                      ? item.comboPickedItems.map((selection, k) => {
                          return (
                            <Flex key={"don" + k}>
                              <Text ml="20px" as="i">
                                - {selection.code} {selection.name} (
                                {selection.chineseName}){" "}
                              </Text>
                            </Flex>
                          );
                        })
                      : null}
                    {item.combo
                      ? item.comboPickedItems.map((selection, k) => {
                          return (
                            <Flex key={"pkt" + k}>
                              <Text ml="20px" as="i">
                                - {selection.code} {selection.name} (
                                {selection.chineseName})
                              </Text>
                            </Flex>
                          );
                        })
                      : null}
                    {item.itemSize !== undefined ? (
                      <Text ml="20px" as="i">
                        {"- " +
                          item.itemSize.charAt(0).toUpperCase() +
                          item.itemSize.slice(1)}
                      </Text>
                    ) : null}

                    <Text ml="20px" as="i" color="#CE2029" fontSize="15px">
                      {item.note !== "" ? "*- " + item.note : null}
                    </Text>
                  </Flex>
                  <Text ml="auto">
                    {method !== "4" ? (
                      item.soupDiscountFlag ? (
                        //discounted soup price
                        <Text>${item.soupDiscountPrice.toFixed(2)}</Text>
                      ) : (
                        <Text>${(item.price * item.quantity).toFixed(2)}</Text>
                      )
                    ) : null}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          );
        })}
        <Text
          fontWeight="bold"
          ml="20px !important"
          color="#CE2029"
          mb="4px !important"
        >
          {orderNote !== "" ? "*Note: " + orderNote : null}
        </Text>
      </VStack>
      {method !== "4" ? (
        <Flex
          color="black"
          fontWeight="bold"
          direction="row"
          mt="7px"
          justify="space-between"
        >
          <Flex direction="column">
            <QRCode size={55} value={"http://kenskowloonkitchen.com/"} />
            <Text fontWeight="normal" fontSize="smaller" mt="auto">
              GST: 74518 0810 RT0001
            </Text>
          </Flex>
          <Flex direction="column" align="flex-end">
            <Text>Subtotal: ${orderPrice.subTotal.toFixed(2)}</Text>

            {orderPrice.additionalCharges !== 0 ? (
              <Text>
                {parseFloat(orderPrice.additionalCharges) < 0
                  ? "Discount: -$" +
                    parseFloat(orderPrice.additionalCharges * -1).toFixed(2)
                  : "Charges: +$" +
                    parseFloat(orderPrice.additionalCharges).toFixed(2)}
              </Text>
            ) : null}
            {(orderPrice.subTotal >= 40.0) &
            (method === "1" || method === "5") ? (
              <>
                <Text>Discount (5%): ${orderPrice.discount.toFixed(2)}</Text>
                <Text>
                  Subtotal w/ Discount: $
                  {orderPrice.subTotalDiscount.toFixed(2)}
                </Text>
              </>
            ) : null}
            {method === "2" ? (
              <Text>Driver Fee: ${orderPrice.deliveryFee.toFixed(2)}</Text>
            ) : null}
            <Text>Tax: ${orderPrice.gst.toFixed(2)}</Text>
            <Text>Total: ${orderPrice.total.toFixed(2)}</Text>
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
}

/*
          <Flex align="end" justify="center" fontWeight="normal" width="100%">
            <Text as="i" justify="center">
              {copy ? "Kitchen/Restaurant Copy" : "Packing/Customer Copy"}
            </Text>
          </Flex>
*/

export default PrintFormat;
/* Old feature to get the colored letter for sweet & sour pork/lemon chicken
  const getColorComboOne = (code) => {
    for (let i = 0; i < sauceSymbolsComboOne.length; i++) {
      if (code === sauceSymbolsComboOne[i].code) {
        return sauceSymbolsDinnerCombo[i].color.charAt(0).toUpperCase();
      }
    }
  };
  const getColorComboDinner = (code) => {
    for (let i = 0; i < sauceSymbolsDinnerCombo.length; i++) {
      if (code === sauceSymbolsDinnerCombo[i].code) {
        return sauceSymbolsDinnerCombo[i].color.charAt(0).toUpperCase();
      }
    }
  };

  const getColor = (code) => {
    for (let i = 0; i < sauceSymbols.length; i++) {
      if (code === sauceSymbols[i].code) {
        return sauceSymbols[i].color.charAt(0).toUpperCase();
      }
    }
  };
  */
