import moment from "moment";
moment().format();

export const offline = () => {
  const currentTime = moment().add(0, "minutes");

  //online orders from 11:00AM - 1:15PM
  const startLunchTime = moment("11:00am", "hh:mm A");
  const endLunchTime = moment("1:30pm", "hh:mm A");

  //online orders from 3:30PM - 8:45PM
  const startDinnerTime = moment("3:30pm", "hh:mm A");
  const endDinnerTime = moment("9:00pm", "hh:mm A");

  //holiday vrs
  const today = moment();
  const christmasDay = moment("12-25", "MM-DD");
  const boxingDay = moment("12-26", "MM-DD");
  const newYears = moment("01-01", "MM-DD");

  //current day
  const day = moment().format("dddd");
  let lunchTimeFlag = currentTime.isBetween(startLunchTime, endLunchTime);
  let dinnerTimeFlag = currentTime.isBetween(startDinnerTime, endDinnerTime);
  let timeFlag;

  if (
    (day === "Saturday" && lunchTimeFlag) ||
    (day === "Sunday" && lunchTimeFlag) ||
    (day === "Monday" && lunchTimeFlag) ||
    (day === "Monday" && dinnerTimeFlag)
  ) {
    timeFlag = false;
  } else if (
    today.isSame(christmasDay, "day" || today.isSame(newYears, "day")) &&
    dinnerTimeFlag
  ) {
    timeFlag = true;
  } else if (today.isSame(boxingDay, "day")) {
    timeFlag = false;
  } else if (lunchTimeFlag || dinnerTimeFlag) {
    timeFlag = true;
  } else {
    timeFlag = false;
  }
  //return (timeFlag = true);
  return timeFlag;
};
