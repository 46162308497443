import React, { useContext, useRef, useEffect, useState } from "react";
import { Flex, Text, Button, IconButton } from "@chakra-ui/react";

import { TakeoutContext } from "../../context/TakeoutContext";
import { useReactToPrint } from "react-to-print";
import { PrintPdfWrapper } from "./Printing/PrintPdfWrapper";
import { AiFillPrinter, AiOutlineClose } from "react-icons/ai";

export default function History() {
  const { orderHistory, setOrderHistory } = useContext(TakeoutContext);
  const handleToggle = (index) => {
    let tempHistory = orderHistory.map((obj) => {
      return { ...obj, toggle: false };
    });
    tempHistory[index].toggle = true;
    setOrderHistory(tempHistory);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    let sessionData = sessionStorage.getItem("orderHistory");
    if (JSON.parse(sessionData) !== null) {
      setOrderHistory(JSON.parse(sessionData));
    }
  }, []);

  const getMethod = (method) => {
    if (method === "1") {
      return "Pick Up";
    } else if (method === "2") {
      return "Delivery";
    } else if (method === "3") {
      return "Dine In";
    } else if (method === "4") {
      return "Skip";
    } else if (method === "5") {
      return "Order";
    }
  };
  return (
    <Flex width="100%" pt={0} position="relative">
      <Flex
        position="sticky"
        height="100vh"
        width="35%"
        direction="column"
        align="center"
        borderRight="1px solid #c4c4c4"
        top="0rem"
        overflow="auto"
        className="history"
        bg="white"
      >
        <Flex width="96%" direction="column" mt="10px">
          {orderHistory.map((order, i) => {
            return (
              <Flex
                key={"his" + i}
                direction="column"
                border={order.toggle ? "solid 2px black" : "solid 2px #c4c4c4"}
                borderRadius="4px"
                width="100%"
                mb="10px"
                cursor="pointer"
                minH="90px"
                shadow="lg"
                bg={order.toggle ? "white" : "#F7F7F7"}
                onClick={() => handleToggle(i)}
              >
                <Flex
                  width="100%"
                  justify="space-between"
                  align="center"
                  fontWeight="bold"
                  pl="5px"
                  pr="5px"
                  mt="5px"
                >
                  <Text mr="auto">
                    {order.currentInvoiceNumber} - {getMethod(order.method)}
                  </Text>
                  <Text>{order.time}</Text>
                </Flex>
                <Flex width="100%" direction="column" pl="5px" pr="5px">
                  <Text>{order.name}</Text>
                  <Text>{order.phone}</Text>
                  <Text>{order.address}</Text>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex>

      <Flex width="100%" height="100vh" align="center" direction="column">
        <Flex justify="space-between" width="90%" mt="1rem">
          <Text fontSize="2xl" fontWeight="bold">
            Customer Order
          </Text>
          <Button
            leftIcon={<AiFillPrinter />}
            colorScheme="green"
            variant="solid"
            onClick={handlePrint}
            width="150px"
          >
            Print
          </Button>
        </Flex>
        <Flex mt="10px" border="1px solid black" width="90%">
          {orderHistory.map((order, i) => {
            if (order.toggle) {
              return (
                <Flex key={"hi" + i} width="100%">
                  <PrintPdfWrapper
                    ref={componentRef}
                    handlePrint={handlePrint}
                    takeoutCart={order.takeoutCart}
                    orderPrice={order.orderPrice}
                    name={order.name}
                    phone={order.phone}
                    address={order.address}
                    method={order.method}
                    currentInvoiceNumber={order.currentInvoiceNumber}
                    time={order.time}
                    table={order.table}
                    orderNote={order.orderNote}
                    skipCode={order.skipCode}
                    history={true}
                    schedule={order.schedule}
                    scheduledTime={order.scheduledTime}
                  ></PrintPdfWrapper>
                </Flex>
              );
            }
            return null;
          })}
        </Flex>
      </Flex>
    </Flex>
  );
}
