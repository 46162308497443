import React, { useState, useRef, useContext, useEffect } from "react";
import {
  Flex,
  Text,
  Icon,
  VStack,
  Button,
  HStack,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Radio,
  Stack,
  Input,
  InputGroup,
  InputLeftAddon,
  RadioGroup,
} from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import { useReactToPrint } from "react-to-print";

import io from "socket.io-client";

import { BsPersonSquare, BsClock } from "react-icons/bs";
import { AiOutlineMail, AiOutlineDollarCircle } from "react-icons/ai";
import { BiPhone, BiPrinter, BiCheckCircle } from "react-icons/bi";
import { PrintPdfWrapper } from "../components/admin-page/Printing/PrintPdfWrapper";
import ConfirmModal from "../components/admin-page/ConfirmModal";
import UpdateModal from "../components/admin-page/UpdateModal";
import TakeOutSystem from "../components/admin-page/TakeOutSystem";
import History from "../components/admin-page/History";
import Summary from "../components/admin-page/Summary";
import NewMenu from "../components/admin-page/NewMenu/NewMenu";
import { TakeoutContext } from "../context/TakeoutContext";
import { discountPercent, taxRate } from "../data/tax";

const socket = io.connect(process.env.REACT_APP_PRODUCTION);
//const socket = io.connect(process.env.REACT_APP_DEV);

const audioElement = new Audio();

function Admin(props) {
  const { admin } = useAuth();

  const {
    customerName,
    customerAddress,
    customerPhone,
    customerTable,
    setCustomerTable,
    setNextButton,
    skipCode,
    setSkipCode,
    setCustomerName,
    setCustomerAddress,
    setCustomerPhone,
    nextButton,
    method,
    setMethod,
    setUniqueInvoice,
  } = useContext(TakeoutContext);

  const {
    isOpen: isUpdateOpen,
    onOpen: onUpdateOpen,
    onClose: onUpdateClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const [customerOrders, setCustomerOrders] = useState([]);
  const [orderIndex, setOrderIndex] = useState(undefined);
  const [orderTime, setOrderTime] = useState();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    let sessionData = sessionStorage.getItem("orders");
    if (JSON.parse(sessionData) !== null) {
      setCustomerOrders(JSON.parse(sessionData));
    }
  }, []);

  useEffect(() => {
    socket.on("receive_status", (data) => {
      let tempOrder = [...customerOrders];
      tempOrder.unshift(data);

      //append a customers order to a list that we map out each time a order is placed
      setCustomerOrders(tempOrder);
    });
  }, [customerOrders]);

  const playAudio = () => {
    //play audio when new order comes in
    audioElement.src = "/ringtone.mp3";
    audioElement.play();
  };

  //emit to specfic id
  const sendToAdmin = () => {
    //ready email object
    let time;
    if (orderTime === undefined) {
      //schedule xx:xx kk
      time = "at " + customerOrders[orderIndex].data.time;
    } else {
      //xx:xx Minutes!
      time = "in " + String(orderTime) + " Minutes!";
    }

    //emit time and order to server
    socket.emit("order_confirmation", {
      time: time,
      data: customerOrders[orderIndex].data,
      id: customerOrders[orderIndex].id,
    });

    setOrderTime(undefined);
  };

  const orderToggle = (index) => {
    audioElement.pause();
    let tempList = customerOrders.map((obj) => {
      return { ...obj, toggle: false };
    });
    tempList[index].toggle = true;
    tempList[index].new = false;
    setOrderIndex(index);
    setCustomerOrders(tempList);
  };

  const resetCustomerInfo = () => {
    setCustomerName("");
    setCustomerPhone("");
    setCustomerAddress("");
    setCustomerTable("");
  };

  const validatePhone = (value) => {
    let formatValue = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    setCustomerPhone(formatValue);
  };

  function RoundNum(num, length) {
    var number = Math.round(num * Math.pow(10, length)) / Math.pow(10, length);
    return number;
  }

  const getOrderPrices = (orderIndex) => {
    //should align with PrintFormat's obj of orderPrices
    let discount = 0.0;
    let originalSubTotal = parseFloat(customerOrders[orderIndex].data.subTotal);
    let tempSubTotal = originalSubTotal;
    let extraCharges = 0.0;

    //add the extra charges to subtotal first before discount
    if (customerOrders[orderIndex].data.additionalCharges > 0) {
      extraCharges = customerOrders[orderIndex].data.additionalCharges;
    } else {
      extraCharges = 0.0;
    }

    tempSubTotal += parseFloat(extraCharges);
    let subTotalDiscount = tempSubTotal;

    //apply 5% discount on pickup orders over $40.0
    if (tempSubTotal >= 40.0) {
      discount = RoundNum(tempSubTotal * discountPercent, 2);
      subTotalDiscount -= discount;
    }

    let gst = RoundNum(subTotalDiscount * taxRate, 2);
    let total = RoundNum(subTotalDiscount + gst, 2);

    const tempOrderPricesObj = {
      subTotal: originalSubTotal,
      discount: discount,
      subTotalDiscount: subTotalDiscount,
      additionalCharges: extraCharges,
      deliveryFee: 0,
      gst: gst,
      total: total,
    };
    return tempOrderPricesObj;
  };

  const randomGenerator = () => {
    //random letter to generate
    const letters = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    const randomIndex = Math.floor(Math.random() * letters.length);
    const randomLetter = letters[randomIndex];
    const randomNumber = Math.floor(Math.random() * 10);
    return randomLetter + randomNumber;
  };

  const generateInvoiceNumber = () => {
    //get past orders and we cannot generate a invoice number that has already been generated
    let invoiceNumberList;

    let invoice = randomGenerator();
    let sessionData = sessionStorage.getItem("orderHistory");
    if (JSON.parse(sessionData) !== null) {
      const tempList = JSON.parse(sessionData);
      invoiceNumberList = tempList.map((obj) => obj.currentInvoiceNumber);

      while (invoiceNumberList.includes(invoice)) {
        if (invoiceNumberList.length === 250) {
          break;
        }
        invoice = randomGenerator();
      }
    }
    setUniqueInvoice(invoice);
  };

  return (
    <Tabs fontFamily="body" size="lg">
      <TabList>
        <Tab>Take Out</Tab>
        <Tab>Online Orders</Tab>
        <Tab>History</Tab>
        <Tab>Summary</Tab>
        <Tab>Menu</Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          {nextButton === 1 ? (
            <TakeOutSystem></TakeOutSystem>
          ) : (
            <Flex width="100%" justify="center" height="70vh" mt="3em">
              <Flex
                width="50%"
                direction="column"
                fontWeight="bold"
                border="1px solid #c4c4c4"
                borderRadius="4px"
              >
                <Text fontSize="24px" m={3}>
                  Select One
                </Text>
                <RadioGroup
                  m={3}
                  onChange={resetCustomerInfo}
                  value={method}
                  mt="0.5rem"
                >
                  <Stack>
                    <Radio
                      size="lg"
                      value="3"
                      colorScheme="green"
                      defaultChecked
                      onChange={(e) => setMethod(e.target.value)}
                    >
                      Dine In
                    </Radio>
                    <Radio
                      size="lg"
                      value="1"
                      colorScheme="green"
                      onChange={(e) => setMethod(e.target.value)}
                    >
                      Pickup
                    </Radio>
                    <Radio
                      size="lg"
                      value="2"
                      colorScheme="green"
                      onChange={(e) => setMethod(e.target.value)}
                    >
                      Delivery
                    </Radio>
                    <Radio
                      size="lg"
                      value="4"
                      colorScheme="green"
                      onChange={(e) => setMethod(e.target.value)}
                    >
                      SkipTheDish
                    </Radio>
                    <Radio
                      size="lg"
                      value="5"
                      colorScheme="green"
                      onChange={(e) => setMethod(e.target.value)}
                    >
                      Modify Previous Order
                    </Radio>
                  </Stack>
                </RadioGroup>

                <Flex m={3} fontSize="20px" direction="column">
                  {method === "3" ? (
                    <InputGroup>
                      <InputLeftAddon children="Table" />
                      <Input
                        type="text"
                        placeholder="ex. A13"
                        onChange={(event) =>
                          setCustomerTable(event.target.value)
                        }
                        value={customerTable}
                      />
                    </InputGroup>
                  ) : null}

                  {method === "1" || method === "2" ? (
                    <>
                      <InputGroup>
                        <InputLeftAddon children="Name (名字)" />
                        <Input
                          type="text"
                          placeholder="ex. Alex"
                          onChange={(event) =>
                            setCustomerName(event.target.value)
                          }
                          value={customerName}
                        />
                      </InputGroup>
                      <InputGroup mt="20px">
                        <InputLeftAddon children="Phone (电话)" />
                        <Input
                          type="tel"
                          placeholder="ex. 403-123-4567"
                          onChange={(event) => {
                            validatePhone(event.target.value);
                          }}
                          value={customerPhone}
                        />
                      </InputGroup>
                    </>
                  ) : null}

                  {method === "2" ? (
                    <>
                      <InputGroup mt="20px">
                        <InputLeftAddon children="Address (地址)" />
                        <Input
                          type="text"
                          placeholder="ex. 12 Glenmore Court SE"
                          onChange={(event) =>
                            setCustomerAddress(event.target.value)
                          }
                          value={customerAddress}
                        />
                      </InputGroup>
                    </>
                  ) : null}

                  {method === "4" ? (
                    <InputGroup>
                      <InputLeftAddon children="Code" />
                      <Input
                        type="text"
                        placeholder="Skip Code"
                        onChange={(event) => setSkipCode(event.target.value)}
                        value={skipCode}
                      />
                    </InputGroup>
                  ) : null}
                </Flex>
                <Flex width="100%">
                  <Button
                    width="100%"
                    colorScheme="red"
                    m={3}
                    onClick={() => resetCustomerInfo()}
                  >
                    Clear
                  </Button>
                  <Button
                    width="100%"
                    colorScheme="green"
                    m={3}
                    onClick={() => {
                      generateInvoiceNumber();
                      setNextButton(1);
                    }}
                  >
                    Next
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          )}
        </TabPanel>
        <TabPanel padding={0}>
          <Flex
            direction="row"
            flex={1}
            mt="2rem"
            width="100%"
            justify="space-between"
          >
            <Flex direction="column" width="30%" align="center">
              <Text fontWeight={700} fontSize="24px" width="90%">
                Online Orders
              </Text>
              {[customerOrders].length > 0 &&
                customerOrders.map((orders, i) => {
                  if (orders.new) {
                    playAudio();
                  }

                  return (
                    <Flex
                      key={"customer-order" + i}
                      borderWidth="2px"
                      borderRadius="4px"
                      borderColor={orders.toggle ? "brand.black" : "#c4c4c4"}
                      mt="1rem"
                      direction="column"
                      width="90%"
                      bg="brand.white"
                      _hover={{
                        boxShadow: "base",

                        cursor: "pointer",
                      }}
                      onClick={() => orderToggle(i)}
                    >
                      <Flex direction="column" m="1rem">
                        <VStack align="flex-start">
                          <Flex
                            width="100%"
                            align="center"
                            justify="space-between"
                          >
                            <Text fontSize="20px">
                              Order #{customerOrders.length - i}
                            </Text>
                            <Text
                              fontWeight={700}
                              color={orders.confirmed ? "#08A045" : "#0077b6"}
                            >
                              {orders.confirmed ? "CONFIRMED" : "NEW ORDER"}
                            </Text>
                          </Flex>

                          <Flex align="center">
                            <Icon w={5} h={5} as={BsPersonSquare} />
                            <Text ml="1rem">{orders.data.name}</Text>
                          </Flex>

                          <Flex align="center">
                            <Icon w={5} h={5} as={AiOutlineMail} />
                            <Text ml="1rem">{orders.data.email}</Text>
                          </Flex>

                          <Flex align="center">
                            <Icon w={5} h={5} as={BiPhone} />
                            <Text ml="1rem">{orders.data.phone}</Text>
                          </Flex>

                          <Flex align="center">
                            <Icon w={5} h={5} as={BsClock} />
                            <Text ml="1rem" fontWeight="bold">
                              {orders.data.schedule
                                ? "For Pickup: " + orders.data.scheduledTime
                                : "ASAP"}
                              {console.log(orders.data)}
                            </Text>
                          </Flex>
                        </VStack>
                      </Flex>
                    </Flex>
                  );
                })}
            </Flex>
            <Flex
              direction="column"
              width="60%"
              position="sticky"
              height="100%"
              top="2rem"
              mr="4rem"
            >
              <Text fontWeight={700} fontSize="24px">
                Order Details
              </Text>

              <HStack justify="flex-end">
                <Flex>
                  <Button
                    width="150px"
                    leftIcon={<BiPrinter />}
                    onClick={handlePrint}
                    colorScheme="green"
                  >
                    Print
                  </Button>
                </Flex>
                <Flex>
                  <Button
                    width="150px"
                    leftIcon={<AiOutlineDollarCircle />}
                    onClick={onUpdateOpen}
                    colorScheme="orange"
                  >
                    Update
                  </Button>
                </Flex>
                <Flex>
                  <Button
                    width="150px"
                    leftIcon={<BiCheckCircle />}
                    onClick={onConfirmOpen}
                    colorScheme="facebook"
                  >
                    Confirm
                  </Button>
                </Flex>
              </HStack>

              <UpdateModal
                customerOrder={
                  orderIndex !== undefined ? customerOrders[orderIndex] : null
                }
                isOpen={isUpdateOpen}
                onClose={onUpdateClose}
                customerOrders={customerOrders}
                orderIndex={orderIndex}
                setOrders={(customerOrders) =>
                  setCustomerOrders(customerOrders)
                }
              ></UpdateModal>

              <ConfirmModal
                customerOrder={
                  orderIndex !== undefined ? customerOrders[orderIndex] : null
                }
                customerOrders={customerOrders}
                setConfirm={(customerOrders) =>
                  setCustomerOrders(customerOrders)
                }
                isOpen={isConfirmOpen}
                onClose={onConfirmClose}
                orderTime={orderTime}
                setTime={(orderTime) => setOrderTime(orderTime)}
                sendToAdmin={sendToAdmin}
                index={orderIndex}
              ></ConfirmModal>

              {customerOrders.map((orders, i) => {
                return orders.toggle ? (
                  <Flex key={"tes" + i} border="1px solid black" mt="1rem">
                    <PrintPdfWrapper
                      ref={componentRef}
                      handlePrint={handlePrint}
                      takeoutCart={orders.data.cart}
                      orderPrice={getOrderPrices(i)}
                      name={orders.data.name}
                      phone={orders.data.phone}
                      method={"5"}
                      currentInvoiceNumber={"Online"}
                      time={orders.data.time}
                      orderNote={orders.data.additionalNotes}
                      history={true}
                      schedule={orders.data.schedule}
                      scheduledTime={orders.data.scheduledTime}
                    ></PrintPdfWrapper>
                  </Flex>
                ) : null;
              })}
            </Flex>
          </Flex>
        </TabPanel>

        <TabPanel padding={0}>
          <History></History>
        </TabPanel>
        <TabPanel padding={0}>
          <Summary></Summary>
        </TabPanel>
        <TabPanel>
          <NewMenu></NewMenu>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default Admin;
