import React, { useContext } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  VStack,
  Text,
} from "@chakra-ui/react";

import { TakeoutContext } from "../../../context/TakeoutContext";

export default function LoadOrderModal({
  onOpenLoad,
  isOpenLoad,
  onCloseLoad,
}) {
  const {
    orderHistory,
    setTakeoutCart,
    setCustomerAddress,
    setCustomerName,
    setCustomerPhone,
    setCustomerTable,
    setLoadOrderIndex,
    setMethod,
    setOrderNote,
    setDeliveryFee,
    setAddCharges,
    setUpdateDeliveryFee,
    setShowCharge,
    setOrderPrice,
    setUniqueInvoice,
  } = useContext(TakeoutContext);
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  //console.log("Order History", orderHistory);

  const loadOrder = (index) => {
    setUniqueInvoice(orderHistory[index].currentInvoiceNumber);
    setLoadOrderIndex(index);

    setMethod(orderHistory[index].method);
    setTakeoutCart(orderHistory[index].takeoutCart);
    setCustomerAddress(orderHistory[index].address);
    setCustomerTable(orderHistory[index].table);
    setCustomerPhone(orderHistory[index].phone);
    setCustomerName(orderHistory[index].name);
    setOrderNote(orderHistory[index].orderNote);
    //setOrderPrice(orderHistory[index].orderPrice);
    setDeliveryFee(parseFloat(orderHistory[index].deliveryFee));
    setUpdateDeliveryFee(parseFloat(orderHistory[index].deliveryFee));
    setAddCharges(parseFloat(orderHistory[index].addCharges));
    setShowCharge(parseFloat(orderHistory[index].showCharge));
  };

  /* get names
  const getNames = () => {
    let temp = data;
    console.log(temp.menu.length);

    for (let i = 0; i < temp.menu.length; i++) {
      //console.log(temp.menu[i].items);
      let category = temp.menu[i].items;

      for (let j = 0; j < category.length; j++) {
        console.log(category[j].price);
      }
    }
  };*/

  return (
    <Modal
      isOpen={isOpenLoad}
      onClose={onCloseLoad}
      isCentered
      blockScrollOnMount
      preserveScrollBarGap
      size="xl"
      scrollBehavior={scrollBehavior}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Load Past Order</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack border="1px solid black">
            {orderHistory.map((order, i) => {
              return (
                <Flex
                  key={"hist" + i}
                  width="50%"
                  justify="space-between"
                  fontWeight="bold"
                  cursor="pointer"
                  bg="blackAlpha.100"
                  onClick={() => {
                    loadOrder(i);
                  }}
                  _hover={{
                    bg: "blackAlpha.300",
                  }}
                >
                  <Text>
                    {order.currentInvoiceNumber !== "Online"
                      ? order.currentInvoiceNumber
                      : "Online Order: " + order.name}
                  </Text>
                  <Text>{order.time}</Text>
                </Flex>
              );
            })}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              onCloseLoad();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
