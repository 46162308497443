import React, { useContext, useEffect, useRef } from "react";
import { Flex, Text, Divider, IconButton, Button } from "@chakra-ui/react";
import { AiFillPrinter } from "react-icons/ai";
import { TakeoutContext } from "../../context/TakeoutContext";
import { CloseIcon } from "@chakra-ui/icons";
import { PrintPdfWrapper } from "./Printing/PrintPdfWrapper";
import { useReactToPrint } from "react-to-print";
import { discountPercent, taxRate } from "../../data/tax";

function TakeoutCart({ orderNote }) {
  const {
    takeoutCart,
    setTakeoutCart,
    loadOrderIndex,
    orderHistory,
    deliveryFee,
    setDeliveryFee,
    updateDeliveryFee,
    addCharges,
    showCharge,
    updateToggle,
    method,
    customerPhone,
    customerAddress,
    customerName,
    customerTable,
    skipCode,
    orderPrice,
    setOrderPrice,
    uniqueInvoice,
  } = useContext(TakeoutContext);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,

    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
    },
  });

  const removeItem = (itemIndex) => {
    //removes item from cart
    setTakeoutCart(takeoutCart.filter((item, i) => itemIndex !== i));
  };

  function RoundNum(num, length) {
    var number = Math.round(num * Math.pow(10, length)) / Math.pow(10, length);
    return number;
  }

  //rounding
  //https://stackoverflow.com/questions/20701029/rounding-issue-in-math-round-tofixed
  useEffect(() => {
    /*
    let subTotal = parseFloat(
      takeoutCart
        .reduce((x, { price, quantity }) => x + price * quantity, 0)
        .toFixed(2)
    );*/

    let subTotal = 0;
    let subTotalDiscount = 0;
    let additionalCharges = 0;

    for (let i = 0; i < takeoutCart.length; i++) {
      if (takeoutCart[i].soupDiscountFlag) {
        subTotal = subTotal + takeoutCart[i].soupDiscountPrice;
      } else {
        subTotal = subTotal + takeoutCart[i].price * takeoutCart[i].quantity;
      }
    }

    //calc delivery fee
    if (
      method === "2" &&
      subTotal < 40 &&
      parseFloat(updateDeliveryFee) === 0
    ) {
      setDeliveryFee(5.0);
    } else if (
      method === "2" &&
      subTotal >= 40 &&
      parseFloat(updateDeliveryFee) === 0
    ) {
      setDeliveryFee(0.0);
    } else if (method === "2" && updateDeliveryFee !== 0) {
      setDeliveryFee(updateDeliveryFee);
    }

    let originalSubTotal = subTotal;

    subTotal += parseFloat(addCharges);

    let discount = 0;
    subTotalDiscount = subTotal;
    if (subTotal >= 40.0 && (method === "1" || method === "5")) {
      discount = subTotal * discountPercent;
      subTotalDiscount -= discount;
    }

    let gst = 0;
    if (method === "2") {
      gst = (subTotal + parseFloat(deliveryFee)) * taxRate;
    } else {
      gst = subTotalDiscount * taxRate;
    }

    let total = 0;
    if (method === "2") {
      total = subTotal + parseFloat(deliveryFee) + gst;
    } else {
      total = subTotalDiscount + gst;
    }

    const tempObj = {
      subTotal: originalSubTotal,
      discount: discount,
      subTotalDiscount: subTotalDiscount,
      additionalCharges: parseFloat(addCharges),
      deliveryFee: parseFloat(deliveryFee),
      gst: RoundNum(gst, 2),
      total: RoundNum(total, 2),
    };

    setOrderPrice(tempObj);
  }, [takeoutCart, method, updateToggle, addCharges, deliveryFee]);

  let customerMethod = "";
  if (method === "1") {
    customerMethod = "Pick Up";
  } else if (method === "2") {
    customerMethod = "Delivery";
  } else if (method === "3") {
    customerMethod = "Dine In";
  } else if (method === "4") {
    customerMethod = "SkipTheDish";
  }

  const dayjs = require("dayjs");
  var now = dayjs();

  return (
    <Flex
      direction="column"
      height="75vh"
      position="sticky"
      top="0rem"
      width={{ base: "100%" }}
      border="1px"
      borderRadius="3px"
      borderTop="none !important"
      borderColor={{ base: "none", lg: "#c4c4c4" }}
    >
      <Flex justify="center" align="center" direction="column" width="100%">
        <Text fontWeight={700} fontSize={24} mb="10px" mt="10px">
          {customerMethod}
        </Text>
        <Divider borderColor="#c4c4c4"></Divider>
        <Flex width="100%" direction="column">
          <Flex direction="column" m="3px">
            <Flex fontWeight="bold">
              <Text>{method !== "5" ? uniqueInvoice : null}</Text>

              <Flex ml="auto">Date: {now.format("YYYY-MM-DD")}</Flex>
            </Flex>

            <Text fontWeight="bold" mr="1.5rem">
              {customerName !== ""
                ? "Name: " + customerName
                : method === "3"
                ? "Table: " + customerTable
                : method === "4"
                ? "SkipTheDish"
                : null}
            </Text>
            <Text fontWeight="bold" mr="1.5rem">
              {customerPhone !== "" ? "Phone: " + customerPhone : null}
            </Text>

            <Text fontWeight="bold" mr="1.5rem">
              {customerAddress !== "" ? "Address: " + customerAddress : null}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Divider alignSelf="center" width="97%" borderColor="#c4c4c4"></Divider>
      <Flex
        className="takeout-cart"
        flex={1}
        overflowY="auto"
        direction="column"
      >
        <Flex direction="column" width="97%" alignSelf="center" mt="1rem">
          {takeoutCart &&
            takeoutCart.map((item, i) => {
              return (
                <Flex
                  key={"tko" + i}
                  direction="column"
                  border="1px solid #c4c4c4"
                  mb="8px"
                  borderRadius="5px"
                  shadow="md"
                >
                  <Flex alignSelf="flex-end">
                    <IconButton
                      variant="ghost"
                      aria-label="cancel item"
                      size="sm"
                      isRound
                      icon={<CloseIcon />}
                      color="black"
                      colorScheme="red"
                      onClick={() => removeItem(i)}
                    />
                  </Flex>
                  <Flex justify="space-between" fontWeight="bold" m="7px">
                    <Text>
                      {item.number}. {item.name} x{" "}
                      {item.half ? 0.5 : item.quantity}
                    </Text>
                    {item.soupDiscountFlag ? (
                      //discounted soup price
                      <Text>${item.soupDiscountPrice.toFixed(2)}</Text>
                    ) : (
                      <Text>${(item.price * item.quantity).toFixed(2)}</Text>
                    )}
                  </Flex>
                  {item.includes &&
                    item.includes.map((selection, j) => {
                      if (selection.chineseName !== "hidden") {
                        return (
                          <Text key={"incj" + j} as="i" ml="7px" mr="7px">
                            -{selection.name}
                          </Text>
                        );
                      } else {
                        return "";
                      }
                    })}
                  {item.special
                    ? item.comboPickedItems.map((selection, k) => {
                        return (
                          <Text key={"doi" + k} as="i" ml="7px" mr="7px">
                            -{selection.code} {selection.name}
                          </Text>
                        );
                      })
                    : null}
                  {item.combo
                    ? item.comboPickedItems.map((selection, k) => {
                        return (
                          <Text key={"cct" + k} as="i" ml="7px" mr="7px">
                            -{selection.code} {selection.name}
                          </Text>
                        );
                      })
                    : null}
                  {item.itemSize !== undefined ? (
                    <Flex ml="7px" mr="7px">
                      <Text as="i">
                        {"Size: " +
                          item.itemSize.charAt(0).toUpperCase() +
                          item.itemSize.slice(1)}
                      </Text>
                    </Flex>
                  ) : null}
                  <Flex ml="7px" mr="7px">
                    <Text as="i">
                      {item.note !== "" ? "Notes: " + item.note : ""}
                    </Text>
                  </Flex>
                </Flex>
              );
            })}
        </Flex>
      </Flex>

      <Flex
        position="fixed"
        width="-webkit-fill-available"
        direction="column"
        align="center"
        bg="brand.white"
        border="0px solid #c4c4c4"
        bottom={0}
        pt="15px"
        pb="15px"
      >
        {orderNote !== "" ? (
          <Flex direction="column" width="97%" alignSelf="center">
            <Text as="i" fontWeight="bold" ml="7px">
              Note: {orderNote}
            </Text>
          </Flex>
        ) : null}
        {method !== "4" ? (
          <Flex
            color="brand.black"
            fontWeight="bold"
            direction="column"
            align="flex-end"
            mr="10px"
            pt="10px"
            width="inherit"
          >
            <Text>Subtotal: ${orderPrice.subTotal.toFixed(2)}</Text>
            {showCharge !== 0 ? (
              <Text>
                {parseFloat(orderPrice.additionalCharges) < 0
                  ? "Discount: -$" +
                    parseFloat(orderPrice.additionalCharges * -1).toFixed(2)
                  : "Charges: +$" +
                    parseFloat(orderPrice.additionalCharges).toFixed(2)}
              </Text>
            ) : null}
            {method === "2" ? (
              <Text>
                Driver Fee: ${parseFloat(orderPrice.deliveryFee).toFixed(2)}
              </Text>
            ) : null}
            {(orderPrice.subTotal >= 40.0) &
            (method === "1" || method === "5") ? (
              <>
                <Text>Discount (5%): ${orderPrice.discount.toFixed(2)}</Text>
                <Text>
                  Subtotal w/ Discount: $
                  {orderPrice.subTotalDiscount.toFixed(2)}
                </Text>
              </>
            ) : null}
            <Text>Tax: ${orderPrice.gst.toFixed(2)}</Text>
            <Text>Total: ${orderPrice.total.toFixed(2)}</Text>
          </Flex>
        ) : null}
        <Flex width="100%" justify="space-around" mt="5px">
          <Button
            width="98%"
            leftIcon={<AiFillPrinter />}
            colorScheme="green"
            variant="solid"
            onClick={handlePrint}
          >
            Print
          </Button>
        </Flex>
      </Flex>

      <Flex display="none">
        <PrintPdfWrapper
          ref={componentRef}
          handlePrint={handlePrint}
          takeoutCart={takeoutCart}
          orderPrice={orderPrice}
          name={
            method === "5" && loadOrderIndex !== undefined
              ? orderHistory[loadOrderIndex].name
              : customerName
          }
          table={customerTable}
          phone={customerPhone}
          address={customerAddress}
          method={method}
          orderNote={orderNote}
          currentInvoiceNumber={uniqueInvoice}
          skipCode={skipCode}
        ></PrintPdfWrapper>
      </Flex>
    </Flex>
  );
}

export default TakeoutCart;
