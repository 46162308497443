import React from "react";
import { Flex, Text, Icon } from "@chakra-ui/react";
import { FaMapMarkerAlt } from "react-icons/fa";
function Footer(props) {
  return (
    <Flex
      direction="column"
      align="center"
      color="brand.beige"
      bg="brand.red"
      fontFamily="glory"
    >
      <Flex justify={{ base: "center", md: "flex-start" }}>
        <Flex direction="row" height="90px">
          <Flex align="flex-end" mr="5px">
            <Text
              fontSize={{ base: "footer.logosm", sm: "footer.logo" }}
              letterSpacing={{ base: -2, sm: -2.5 }}
              lineHeight="27px"
              fontFamily="langar"
              whiteSpace="nowrap"
            >
              <Text
                as="span"
                fontSize={{ base: "footer.capssm", sm: "footer.caps" }}
              >
                K
              </Text>
              owloon{" "}
              <Text
                as="span"
                fontSize={{ base: "footer.capssm", sm: "footer.caps" }}
              >
                K
              </Text>
              itchen
            </Text>
          </Flex>
          <Flex
            justify="flex-end"
            direction="column"
            fontSize={{ base: "24px", sm: "28px" }}
            lineHeight="1"
            fontFamily="chinese"
          >
            <Text>九</Text>
            <Text>龙</Text>
            <Text>冰</Text>
            <Text>室</Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        direction="column"
        align="center"
        fontFamily="glory"
        fontSize="footer.base"
        fontWeight="bold"
        mt="50px"
        whiteSpace="nowrap"
      >
        <Text mb="24px">Hours:</Text>

        <Flex>
          <Text>Lunch:&nbsp;&nbsp;</Text>
          <Flex direction="column">
            <Flex>
              <Text>Tuesday - Friday</Text>
              <Text>:&nbsp;&nbsp;</Text>
              <Text>11:00am - 1:30pm</Text>
            </Flex>
            <Flex>
              <Flex mb="8px">
                <Text>Saturday - Monday</Text>
                <Text>:&nbsp;&nbsp;</Text>
                <Text>Closed</Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex>
          <Text>Dinner:&nbsp;&nbsp;</Text>
          <Text>Tuesday - Sunday</Text>
          <Text>:&nbsp;&nbsp;</Text>
          <Text>3:30pm - 9:00pm</Text>
        </Flex>
        <Flex>
          <Flex mb="8px">
            <Text>Monday</Text>
            <Text>:&nbsp;&nbsp;</Text>
            <Text>Closed</Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        direction="column"
        align="center"
        fontFamily="glory"
        fontSize="footer.base"
        fontWeight="bold"
        mt="45px"
        mb="45px"
      >
        <Text>2118 Glenmore Court SE</Text>
        <Text>(403) 279-4471</Text>
        <Text>(403) 279-4472</Text>
      </Flex>

      <Flex direction="column" align="center" fontSize="footer.sm">
        <Text>Join the Neo Culture.</Text>
        <Text>© Kowloon Kitchen 2025</Text>
      </Flex>
    </Flex>
  );
}

export default Footer;
